.upper-section{
    display: flex;
    flex-direction: row;
    color: #4A5056;
    justify-content: space-between;
}

.text-container{
    width: 50%;
    padding-left: 5%;
    margin-top: 10%;
}

.main-text{
    font-size: 40px;
}

.intro-para{
    text-align: justify;
    font-size: larger;
    margin-left: 10%;
    margin-right: 10%;
}
.main-img{
    width: 30%;
    right: 75%;
    z-index: 1;
    padding-right: 10%;
}

@media screen and (max-width: 748px){
    .upper-section{
    display: flex;
    flex-direction: column;
    }
    .intro-para{
        width: 150%;
    }
    .main-text{
        margin-left: 5%;
    }

    .main-img{
        width: 90%;
        z-index: 1;
    }

    .text-container{
        width: 50%;
        z-index: 2;

    }
}