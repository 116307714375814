/* Default Styles */
.about-main-container {
    display: flex;
    flex-direction: row;
    color: #4A5056;
    justify-content: space-between;
    padding-top: 20px; /* Add some padding around the container */
}

.about-text-container {
    width: 50%;
    padding-right: 5%;
    margin-top: 5%;
}

.about-title {
    text-align: left;
}

p {
    text-align: justify;
    font-size: larger;
}

.about-img {
    width: 30%;
    z-index: 1;
    padding-left: 10%;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .about-main-container {
        flex-direction: column; /* Stack elements vertically on small screens */
        align-items: center; /* Center-align items */
    }

    .about-text-container {
        width: 90%; /* Make the text container take full width */
        padding-right: 0; /* Remove right padding */
        margin-top: 2%; /* Adjust top margin */
        margin-left: 3%;
        margin-right: 3%;

    }

    .about-img {
        width: 80%; /* Make the image larger on smaller screens */
        padding-left: 0; /* Remove left padding */
        margin-top: 10px; /* Add some space above the image */
    }
}

/* Extra Small Screens */
@media (max-width: 480px) {
    p {
        font-size: medium; /* Adjust font size for very small screens */
    }
}
