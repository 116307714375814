body{
    color: #4A5056;
}
.project-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 5px;
  }
  .vertical-timeline-element-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
/* Custom styles for timeline dates */
.vertical-timeline-element-date {
    font-size: 16px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Custom styles for timeline elements */
  .vertical-timeline-element-content {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .date-on-icon{
    padding-top: 30%;
  }