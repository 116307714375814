.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 85.333px;
    background: white;
    box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
.navbar--items > ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 42.667px;
    text-decoration: none;
}
.navbar--items ul > li > a {
    text-decoration: none;
}

.navbar-item-name{
    text-decoration: none;
}
.navbar--content {
    color: #4A5056;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}
.navbar--active-content {
    color: black;
}

.nav__hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
}
.nav__hamburger__line {
    width: 30px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
    transition: 0.4s;
}

@media screen and (max-width: 1200px) {
    .nav__hamburger {
        display: flex;
       z-index: 1000;
       
    }
    .navbar--items {
        display: flex;
    }
    .navbar--items {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255);
        top: -20rem;
        left: 0;
        width: 100%;
        transition: all ease-in-out 0.4s;
    }
    .navbar--items ul {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 3rem 0 0.6rem;
        z-index: -100;
    }
    .navbar--items ul li {
        text-align: center;
    }
    .navbar--items ul li a {
        padding: 0.5rem;
    }
    .navbar--items {
        z-index: -1000;
    }
    .navbar--items.active {
        top: 30px;
        /* background-color: white; */
        z-index: 100;
    }
    .nav__hamburger.active .nav__hamburger__line:nth-child(1) {
        transform: rotate(45deg) translate(0.45rem, 0.1875rem);
        z-index: 100;
    }
    .nav__hamburger.active .nav__hamburger__line:nth-child(2) {
        opacity: 0;
        z-index: 100;
    }
    .nav__hamburger.active .nav__hamburger__line:nth-child(3) {
        transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
        z-index: 100;
    }
}
