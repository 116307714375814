.footer {
    background-color: #F1F1F1;
    color: #4A5056;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .footer-links-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
  }
  
  .footer-links {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .footer-links li {
    display: inline-block;
    margin: 0 10px;
  }
  
  .footer-links li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-logo {
    width: 5rem;
  }
  
  .footer-links-img {
    width: 2rem;
    margin: 5px;
  }
  
  
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  
    .footer-links {
      margin-top: 20px;
    }
  
    .footer-links-img {
      margin-top: 15px;
    }
  }