.contact-main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: white;
}

.contact-info-section-title {
    text-align: left;
    margin-left: 25%;
}

.contact-form-section-title{
    text-align: left;
    margin-left: 10%;
}
.contact-info-container {
    flex-direction: column;
    justify-content: center;
}

.contact-info-container #para1 {
    margin-left: 25%;
    margin-right: 15%;
}

.contact-info-item {
    display: flex;
    flex-direction: row;
    margin-left: 25%;
    margin-right: 25%;
}

.contact-info-img {
    width: 2rem;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: left;
    margin-bottom: 1rem;
}

.contact-form-item .input-field {
    width: 100%;
    height: 2rem;
    margin-left: 15%;
    margin-bottom: 2%;
    border: 1px solid #BEBEBE;
    border-radius: 2px;
}

.contact-form-item #message {
    height: 8rem !important;
}

.contact-send-button {
    width: 6rem;
    margin-left: 16%;
    background-color: #fff;
    height: 2rem;
    border: 1px solid #BEBEBE;
    border-radius: 1px;
    color: #4A5056;
}

.contact-send-button:hover {
    
    background-color: #edecec;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .contact-main-container {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }

    .contact-info-section-title {
        margin-left: 5%;
        text-align: left;
    }
    
    .contact-form-section-title{
        margin-left: 0%;
        text-align: left;
    }

    .contact-info-container #para1 {
        margin-left: 5%;
        margin-right: 0;
        text-align: left;

    }

    .contact-info-item {
        flex-direction: row;
        align-items: center;
        margin-left: 5%;
        margin-right: 0;
    }

    .contact-info-img {
        width: 1.5rem;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .contact-form-container {
        width: 90%;
        align-items: left;
    }

    .contact-form-item .input-field {
        width: 100%;
        height: 2.5rem;
        margin-left: 0;
        margin-bottom: 1rem;
    }

    .contact-send-button {
        width: 100%;
        max-width: 150px;
        margin-left: 0;
        height: 2.5rem;
    }
}

@media (max-width: 480px) {
    .contact-info-item {
        margin-bottom: 1rem;
    }
}
